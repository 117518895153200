import { User } from "../models/user";
import { Either } from "../../core/helpers/either";
import { Failure } from "../../core/helpers/failure";
import { CreateUserDto } from "../dto/create-user-dto";
import { UpdateUserPasswordBody } from "@domain/models/update-user-password-body";
import { ValidateVerificationOtpEmailBody } from "@domain/models/validate-verification-otp-email-body";
import { GetUserParams } from "@domain/models/getUser-params";
import { AddMetamapAttemptBody } from "@domain/models/add-metamap-attempt-body";

export abstract class UserRepository {
    abstract registerUserData(userData: CreateUserDto): Promise<Either<Failure, boolean>>;
    abstract getUserData(params: GetUserParams): Promise<Either<Failure, User>>;
    abstract telephoneAlreadyExists(userTelephone: string): Promise<Either<Failure, boolean>>;
    abstract sendVerificationOtpEmail(email: string): Promise<Either<Failure, void>>;
    abstract validateVerificationOtpEmail(body: ValidateVerificationOtpEmailBody): Promise<Either<Failure, boolean>>;
    abstract updateUserPassword(body: UpdateUserPasswordBody): Promise<Either<Failure, void>>;
    abstract addMetamapAttempt(body: AddMetamapAttemptBody): Promise<Either<Failure, boolean>>;
}